import React, { useEffect, useState } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import {
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Card,
  CardContent,
  Typography,
  MenuItem,
  Grid,
  Box
} from "@mui/material";

function ListShareHolders({ accessToken, handleBack, heading, Fields, handleNext, user, capturedData }) {
  const [validationErrors, setValidationErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailure, setIsFailure] = useState<boolean>(false);
  const [message, setMessage] = useState("");
  const [formData, SetFormData] = useState({
    name: "",
    shares_no: "",
    shares_per: "",
    pan: "",
  });

  const [shareholder, setShareholders] = useState([formData, ...capturedData?.list_holders||[]]);

  const EditDataHandler = (params) => {
    const data = params.row;
    const updateShareHolder = shareholder.filter(e => e.pan !== data.pan);
    SetFormData({ ...data });
    setShareholders(updateShareHolder);
  };

  const [columns2, setColumns2] = useState([
    { field: "id", headerName: "ID", width: 80 },
    { field: "name", headerName: "Name of Partner", width: 300 },
    {
      field: "pan", headerName: "PAN", width: 280,
    },
    {
      field: "Actions",
      headerName: "Actions",
      width: 240,
      renderCell: (params: any) => {
        return (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Grid
              item
              className="bills-pay"
              py={1}
              px={2}
              style={{ marginRight: "1rem" }}
              onClick={(event) => {
                EditDataHandler(params);
              }}
            >
              EDIT
            </Grid>
          </div>
        );
      },
    },
  ]);

  const [columns1, setColumns1] = useState([
    { field: "id", headerName: "ID", width: 80 },
    { field: "name", headerName: "Name of Shareholder", width: 200 },
    { field: "shares_no", headerName: "No. of Shares Held", width: 140 },
    {
      field: "shares_per",
      headerName: "Percentage Shareholding",
      width: 240,
    },
    {
      field: "pan", headerName: "PAN", width: 150,
    },
    {
      field: "Actions",
      headerName: "Actions",
      width: 140,
      renderCell: (params: any) => {
        return (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Grid
              item
              className="bills-pay"
              py={1}
              px={2}
              style={{ marginRight: "1rem" }}
              onClick={(event) => {
                EditDataHandler(params);
              }}
            >
              EDIT
            </Grid>
          </div>
        );
      },
    },
  ]);

  const handleChange = event => {
    const { name, value } = event.target;
    SetFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const AddShareHolderHandler = (event: any) => {
    event.preventDefault();
    const existingPancards = shareholder.map(
      ele => ele.pan.toUpperCase()
    );
    if (existingPancards.includes(formData.pan.toUpperCase())) {
      setIsFailure(true);
      setMessage("Please Enter Unique Pancard Number");
      return;
    }
    shareholder.push(formData)
    setShareholders(shareholder);
    SetFormData({
      name: "",
      shares_per: "",
      shares_no: "",
      pan: "",
    });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    let total = 0;

    shareholder.forEach(ele => {
      Object.entries(ele).forEach(([key, value]) => {
        if (key == "shares_per") {
          total += Number(value);
        }
      });
    });

    if (capturedData.investorType == "Company" && (total > 100 || total < 100)) {
      setIsFailure(true);
      setIsLoading(false);
      setMessage("Sum of Percentage Share Holding must be 100%");
      return;
    }
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_HOST}v1/user/investment/shareholders`,
        { data: shareholder.slice(1), doc_id: capturedData.doc_id },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then(res => {
        const { data } = res;
        if (!data.succ) {
          setIsLoading(false);
          setIsFailure(true);
          setMessage(data.message);
          return;
        }
        setIsLoading(false);
        setIsSuccess(true);
        setMessage(data.message);
        setTimeout(() => {
          handleNext();
        }, 2000);
      })
      .catch(error => {
        setIsLoading(false);
        setIsFailure(true);
        setMessage(error);
        return;
      });
  };
  console.log(Fields)
  return (
    <>
      <Card sx={{ maxWidth:600,margin: "0 auto" }}>
        <CardContent>
          <form onSubmit={AddShareHolderHandler} style={{ width: "100%" }}>
            <Typography variant="subtitle1">{heading}</Typography>
            {Fields?.map((ele, key) => {
              return <div key={key} >
                <TextField
                  label={ele.label}
                  onChange={handleChange}
                  type={ele.type}
                  value={formData[ele.name]}
                  inputProps={{ pattern: ele.name == "pan" && "[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}" }}
                  name={ele.name}
                  required
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  focused={ele.type == "date" || ele.type == "file"}
                  select={ele.dropdown}
                  error={!!validationErrors[ele.name]} // Check if the field has an error
                  helperText={validationErrors[ele.name]} /// Display the error message
                >
                  {ele.dropdown && ele.value.map((val) =>
                    <MenuItem value={val} >{val}</MenuItem>
                  )}
                </TextField>
              </div>
            })}
            {/* <TextField
              label="Name of Shareholder"
              onChange={handleChange}
              value={formData.name}
              name="name"
              required
              variant="outlined"
              margin="normal"
              fullWidth
              error={!!validationErrors.Name} // Check if the field has an error
              helperText={validationErrors.Name} /// Display the error message
            />
            <TextField
              label="Number of Shares Held"
              onChange={handleChange}
              value={formData.no_share_held}
              name="no_share_held"
              required
              type="Number"
              variant="outlined"
              margin="normal"
              fullWidth
              error={!!validationErrors.Number} // Check if the field has an error
              helperText={validationErrors.Number} // Display the error message
            />

            <TextField
              label="Percentage Shareholding"
              onChange={handleChange}
              name="percentage_shareholding"
              required
              type="Number"
              value={formData.percentage_shareholding}
              variant="outlined"
              margin="normal"
              fullWidth
              error={!!validationErrors.Dob} // Check if the field has an error
              helperText={validationErrors.Dob} // Display the error message
            ></TextField>
            <TextField
              label="Pancard Number"
              onChange={handleChange}
              name="pancard"
              value={formData.pancard}
              required
              variant="outlined"
              inputProps={{pattern: "[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}"}}
              margin="normal"
              fullWidth
              error={!!validationErrors.pan} // Check if the field has an error
              helperText={validationErrors.pan} // Display the error message
            ></TextField> */}

            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isLoading}
              fullWidth
              sx={{ marginTop: 2 }}
            >
              {capturedData.investorType == "Company" ? "ADD SHAREHOLDERS" : "Add Partner"}
            </Button>
          </form>
        </CardContent>
        <Snackbar
          open={isSuccess}
          autoHideDuration={3000}
          onClose={() => setIsSuccess(false)}
          message=""
          sx={{ marginBottom: 2 }}
        >
          <Alert severity="success" sx={{ width: "100%" }} className="snack">
            {message.length > 1 ? message : "File is uploading..."}
          </Alert>
        </Snackbar>

        <Snackbar
          open={isFailure}
          autoHideDuration={3000}
          onClose={() => setIsFailure(false)}
          sx={{ marginBottom: 2 }}
        >
          <Alert severity="error" sx={{ width: "100%" }} className="snack">
            {message}
          </Alert>
        </Snackbar>
      </Card>
      {shareholder?.length > 1 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "60vh",
            width: "100%",
          }}
        >
          <Card
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              height: "40vh",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
                flexDirection: "row",
                width: {xs:"94vw",md:"70vw"},
              }}
            >
              <DataGrid
                rows={shareholder?.slice(1).map((each: any, idx: number) => {
                  return {
                    ...each,
                    id: idx + 1,
                  };
                })}
                columns={(capturedData.investorType == "Company" ? columns1 : columns2).map(each => {
                  return { ...each, headerAlign: 'center', align: 'center', };
                })}
                hideFooterPagination
                hideFooterSelectedRowCount
              />
            </Box>
          </Card>
          {shareholder?.length > 2 && (
            <Box sx={{ display: "flex", flex: "row", gap: 4 }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleBack}
                disabled={isLoading}
                fullWidth
                sx={{ marginTop: 2 }}
              >
                {
                  "Back"
                }
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleSubmit}
                disabled={isLoading}
                fullWidth
                sx={{ marginTop: 2 }}
              >
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Submit"
                )}
              </Button>
            </Box>
          )}
        </div>
      )}
    </>
  );
}

export default ListShareHolders;
