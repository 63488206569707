import React, { useEffect, useState } from 'react';
import { TextField, Button, Modal, Box, MenuItem, CircularProgress, Alert, Snackbar, Card, CardContent, Typography, Divider } from '@mui/material';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppContext } from '../../../Store/AppContext';
import RTGSPayment from './RTGSPayment';
import { Clear } from '@mui/icons-material';
import { FormatDecimal, parseNumber } from '../../../utils/formatNumber';
import { NumericFormat } from 'react-number-format';
import { RecommendationLogic } from './RecommendationLogic';
import { ProductCode, BankName, PaymentMode, isholiday, Name_Days } from '../../../Data/data';

const CreateMultipleOrdersNSE = ({ accessToken }) => {
    const [storeState, dispatch] = useAppContext();
    const [open, setOpen] = React.useState(false);
    const [OTP, setOTP] = useState("");
    const [modelName, setModelName] = useState("");
    const handleClose = () => { setOpen(false); }
    const navigate = useNavigate();
    const { state }: any = useLocation();
    const [validationErrors, setValidationErrors] = useState<any>({});
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isFailure, setIsFailure] = useState(false);
    const [TransactionCount, setTransactionCount] = useState<any>([])
    const [msg, setMsg] = React.useState("");
    const [userBank, setUserBank] = useState(storeState.ACTIVEINVETOR?.bank_list)

    const BankNotOnlinepayment = ["CSBF", "BNPP", "SBOM"]

    const dateConverter = (str) => {
        const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        var date = new Date(str);
        var mnth = (date.getMonth());
        var day = ("0" + date.getDate()).slice(-2);
        var year = date.getFullYear();
        return `${day}-${month[mnth]}-${year}`;
    }

    const TimeHorizon = [
        "0 - 3 Months",
        "3 - 12 Months",
        "More than 1 year"
    ]

    const [formData, setFormData] = useState({
        "iin": storeState?.ACTIVEINVETOR?.folio.Folio,
        "sub_trxn_type": "N",
        "poa": "N",
        "poa_bank_trxn_type": "",
        "trxn_acceptance": "", //ALL for all
        "demat_user": "N",
        "dp_id": "",
        "bank": storeState?.ACTIVEINVETOR?.bank_list[0]?.BANK_CODE,
        "ac_no": storeState?.ACTIVEINVETOR?.bank_list[0]?.ACNUM,
        "ifsc_code": storeState?.ACTIVEINVETOR?.bank_list[0]?.IFSC,
        "sub_broker_arn_code": "",
        "sub_broker_code": "",
        "euin_opted": "Y",
        "trxn_execution": "",
        "remarks": "",
        "payment_mode": "",
        "billdesk_bank": storeState?.ACTIVEINVETOR?.bank_list[0]?.BANK_CODE,
        "instrm_bank": "HDF",
        "instrm_ac_no": "",
        "instrm_no": "",
        "instrm_date": "",
        "instrm_branch": "",
        "instrm_charges": "",
        "micr": "",
        "rtgs_code": storeState?.ACTIVEINVETOR?.bank_list[0]?.IFSC,
        "neft_ifsc": "",
        "advisory_charge": "",
        "dd_charge": "",
        "cheque_deposit_mode": "",
        "debit_amount_type": "",
        "sip_micr_no": "",
        "sip_bank": "",
        "sip_branch": "",
        "sip_acc_no": "",
        "sip_ac_type": "",
        "sip_ifsc_code": "",
        "sip_paymech": "",
        "umrn": storeState?.ACTIVEINVETOR?.folio.umrn ? storeState?.ACTIVEINVETOR?.folio?.umrn : "", //auot demante
        "ach_amt": "",
        "ach_fromdate": "",
        "ach_enddate": "",
        "until_cancelled": "",
        "Return_paymnt_flag": "N",
        "Client_callback_url": "",
        "Bank_holder_name": "",
        "Bank_holder_name1": "",
        "Bank_holder_name2": "",
        "trxn_initiator": "",
        "trans_count": "",
        "utr_no": "",
        "transfer_date": "",
        "investor_auth_log": "",
        "ach_exist": "",
        "process_mode": "",
        "channel_type": "",
        "folio": "",
        "product_code": "",
        "amc": "",
        "ft_acc_no": "",
        "reinvest": "Z",
        "amount": "",
        "sip_from_date": "",
        "sip_end_date": "",
        "sip_freq": "",
        "sip_amount": "",
        "sip_period_day": "",
        "input_ref_no": "",
        "perpetual_flag": "",
        "insurance_enabled": "",
        "GOAL_BASED_SIP": "",
        "GOAL_TYPE": "",
        "GOAL_AMOUNT": "",
        "FREEDOM_SIP": "",
        "FREEDOM_TARGET_SCHEME": "",
        "FREEDOM_TENURE": "",
        "FREEDOM_SWP_AMOUNT": "",
        "FREEDOM_SCHEME_OPTION": "",
        "time_horizon": "",
        "instrm_amount": "",  //sum of all the scheme amounts
        "PayMode": "xxxxxxxxx",
        "TransactionData": "",
        "fundType": "Various funds through NSE",
        "view_amount": ""
    })

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name == "ac_no") {
            const bank = userBank.filter((ele) => ele.ACNUM == value)[0];
            setFormData((prev) => ({
                ...prev,
                "rtgs_code": bank?.IFSC,
                "billdesk_bank": bank?.BANK_CODE,
                "bank": bank?.BANK_CODE,
                "ac_no": bank?.ACNUM,
                "ifsc_code": bank?.IFSC,
            }))
        } else if (name == "time_horizon") {
            RecommendationLogic(storeState.ACTIVEINVETOR.type, value, formData.instrm_amount, setTransactionCount)
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
                "trans_count": TransactionCount.length,
                "TransactionData": TransactionCount
            }))
        } else if (name == "view_amount") {
            const numValue = parseNumber(value).toString();
            RecommendationLogic(storeState.ACTIVEINVETOR.type, formData.time_horizon, numValue, setTransactionCount)
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
                "instrm_amount": numValue,
                "trans_count": TransactionCount.length,
                "TransactionData": TransactionCount,
            }))
        }
        else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
                "trans_count": TransactionCount.length,
                "TransactionData": TransactionCount
            }));
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        let Amount = 0;

        TransactionCount.map((ele) => {
            Amount += Number(ele.Amount);
        })
        if (Amount !== Number(formData.instrm_amount)) {
            setIsFailure(true);
            setMsg("Sum of Amount should be equal to Invesment Amount");
            setIsLoading(false)
            return;
        } else {
            setOpen(true)
            if (formData.payment_mode == "OL" || formData.payment_mode == "UPI") {
                if (BankNotOnlinepayment.includes(formData.billdesk_bank) && formData.payment_mode == "OL") {
                    setMsg(`Online Payment Method not available for ${BankName[formData.billdesk_bank]} bank `)
                    setIsFailure(true);
                    return;
                }
                setModelName("OL");
                const Data = { ...formData, "trans_count": TransactionCount.length, "TransactionData": TransactionCount }
                axios.post(`${process.env.REACT_APP_BACKEND_HOST}v1/user/investment/create-order`, Data,
                    {
                        headers: { Authorization: `Bearer ${accessToken}` }
                    }).then(res => {
                        const { data } = res;
                        if (!data.succ) {
                            setIsLoading(false);
                            setMsg(data.message)
                            setIsFailure(true);
                            return;
                        }
                        setIsSuccess(true);
                        setIsLoading(false);
                        setMsg(`Order submitted successfully for Rs ${formData.instrm_amount}`)
                        setTimeout(() => {
                            navigate(`/dashboardAdmin/investment/nse/details/${storeState.ACTIVEINVETOR.folio.Folio}`)
                        }, 3000);
                    }).catch(({ response }) => {
                        setIsLoading(false);
                        const { data } = response;
                        //setValidationErrors(data.validationErrors);
                    })
            } else if (formData.payment_mode == "M") {
                setModelName(formData.payment_mode);
                // if (BankNotOnlinepayment.includes(formData.billdesk_bank) && formData.payment_mode == "M") {
                //     setMsg(`Online Payment Method not available for ${BankName[formData.billdesk_bank]} bank `)
                //     setIsFailure(true);
                //     return;
                // }
                if (formData.umrn == "") {
                    axios.post(`${process.env.REACT_APP_BACKEND_HOST}v1/user/investment/nse/umrn`, {
                        iin: storeState.ACTIVEINVETOR.folio.Folio,
                        folio_id: storeState.ACTIVEINVETOR.folio._id
                    }, {
                        headers: { Authorization: `Bearer ${accessToken}` },
                    }).then((response) => {
                        const { data } = response;
                        console.log(data)
                        if (!data.succ) {
                            setIsLoading(false);
                            setMsg(data.message)
                            setIsFailure(true);
                            return;
                        }
                        const Data = { ...formData, "umrn": data.umrn, "trans_count": TransactionCount.length, "TransactionData": TransactionCount }
                        axios.post(`${process.env.REACT_APP_BACKEND_HOST}v1/user/investment/create-order`, Data,
                            {
                                headers: { Authorization: `Bearer ${accessToken}` }
                            }).then(res => {
                                const resData = res.data;
                                if (!resData.succ) {
                                    setIsLoading(false);
                                    setMsg(resData.message)
                                    setIsFailure(true);
                                    return;
                                }
                                setIsLoading(false);
                                setIsSuccess(true);
                                setMsg(`Order submitted successfully for Rs ${formData.instrm_amount}`)
                                setTimeout(() => {
                                    navigate(`/dashboardAdmin/investment/nse/details/${storeState.ACTIVEINVETOR.folio.Folio}`)
                                }, 3000);
                            }).catch(({ response }) => {
                                setIsLoading(false);
                                setIsFailure(true)
                                const { data } = response;
                                setMsg(data?.message)
                                //setValidationErrors(data.validationErrors);
                            })
                    }).catch((error) => {
                        setIsLoading(false);
                        setIsFailure(true);
                        const { data } = error;
                        setMsg(data.message)
                    })
                } else {
                    const Data = { ...formData, "trans_count": TransactionCount.length, "TransactionData": TransactionCount }
                    axios.post(`${process.env.REACT_APP_BACKEND_HOST}v1/user/investment/create-order`, Data,
                        {
                            headers: { Authorization: `Bearer ${accessToken}` }
                        }).then(res => {
                            const resData = res.data;
                            if (!resData.succ) {
                                setIsLoading(false);
                                setMsg(resData.message)
                                setIsFailure(true);
                                return;
                            }
                            setIsLoading(false);
                            setIsSuccess(true);
                            setMsg(`Order submitted successfully for Rs ${formData.instrm_amount}`)
                            setTimeout(() => {
                                navigate(`/dashboardAdmin/investment/nse/details/${storeState.ACTIVEINVETOR.folio.Folio}`)
                            }, 3000);
                        }).catch(({ response }) => {
                            setIsLoading(false);
                            setIsFailure(true)
                            const { data } = response;
                            setMsg(data?.message)
                            //setValidationErrors(data.validationErrors);
                        })
                }

            } else if (formData.payment_mode == "TR") {
                setModelName("TR")
                setIsLoading(false)
            }
        }
    };

    const handleCloseSnackbar = () => {
        setIsFailure(false);
    };

    const HandlerRemoveScheme = (event) => {
        const data = TransactionCount.filter((ele) => ele.Scheme !== event.Scheme);
        setTransactionCount(data);
    }

    const HandleAddOtherFunds = () => {
        const data = {
            Funds: "",
            Scheme: "",
            Amount: "",
            folio: "",
            view_amount: "",
        };
        setTransactionCount((prev) => [...prev, data]);
    }

    const HandleChangeTransactionCount = (event, id) => {
        event.preventDefault();
        const { name, value } = event.target;
        const newData = [...TransactionCount];
        console.log(newData)
        if (name == "Scheme") {
            newData[id] = {
                ...newData[id],
                [name]: ProductCode.filter((ele) => ele.id === value)[0].code,
                ["Funds"]: ProductCode.filter((ele) => ele.id === value)[0].AMC,
            };
        } else if (name == "view_amount") {
            newData[id] = {
                ...newData[id],
                [name]: value,
                ["Amount"]: parseNumber(value)
            };
        }
        setTransactionCount(newData);
    }
    return (
        <>
            {["Sunday", "Saturday"].includes(Name_Days) ? <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", marginTop: "2rem" }}><Typography sx={{ background: "yellow", padding: "4px", borderRadius: "10px", textAlign: "center",width:{xs:"90%",sm:"600px"}}} >{`Kindly note that today is a holiday as exchanges are closed on ${Name_Days}. Also, ${dateConverter(isholiday?.date)} is a holiday on account of ${isholiday?.Desc}.`}</Typography></div> : <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", marginTop: "2rem" }}><Typography sx={{ background: "yellow", padding: "4px", borderRadius: "10px", textAlign: "center", width:{xs:"90%",sm:"600px"} }} >{`Kindly note that ${dateConverter(isholiday?.date)} is a holiday on account of ${isholiday?.Desc}.`}</Typography></div>}
            <div style={{ margin: "2rem" }}>
                <Card sx={{ maxWidth: 600, margin: '0 auto' }}>
                    <CardContent  >
                        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                            <NumericFormat
                                type="text"
                                label="Amount"
                                name="view_amount"
                                value={formData.view_amount}
                                onChange={handleChange}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                error={!!validationErrors.view_amount}
                                helperText={validationErrors.view_amount}
                                required thousandsGroupStyle="lakh" thousandSeparator=","
                                customInput={TextField}
                                decimalScale={2}
                            />
                            <TextField
                                label="Time Horizon"
                                name="time_horizon"
                                onChange={handleChange}
                                value={formData.time_horizon}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                error={!!validationErrors.product_code}
                                helperText={validationErrors.product_code}
                                required
                                select
                            >
                                {TimeHorizon.map((each, idx) => (
                                    <MenuItem key={idx} value={each}>
                                        {each}
                                    </MenuItem>
                                ))}
                            </TextField>
                            {TransactionCount.length > 0 && <>
                                <Typography sx={{ fontWeight: 600 }} >Our Recommendation</Typography>
                                {TransactionCount.map((ele, idx) => {
                                    console.log(TransactionCount)
                                    return <Box sx={{ display: "flex", flexDirection: "row", gap: "20px", alignItems: "center" }} >
                                        {ele.Scheme !== "" &&
                                            <TextField
                                                label="Scheme"
                                                name="Scheme"
                                                onChange={(e) => HandleChangeTransactionCount(e, idx)}
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                error={!!validationErrors.Scheme}
                                                helperText={validationErrors.Scheme}
                                                required
                                                value={ele.Scheme}
                                                select
                                                disabled
                                                
                                            >
                                                <MenuItem value={ele.Scheme} key={ele.id} >{ProductCode.filter((element) => element.code == ele.Scheme && element.AMC == ele.Funds)[0]?.name}</MenuItem>
                                            </TextField>
                                        }
                                        {ele.Scheme == "" && <TextField
                                            label="Scheme"
                                            name="Scheme"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            onChange={(e) => HandleChangeTransactionCount(e, idx)}
                                            error={!!validationErrors.Scheme}
                                            helperText={validationErrors.Scheme}
                                            required
                                            select
                                        >
                                            {ProductCode.filter(ele =>
                                                !TransactionCount.some(item => item.Scheme.includes(ele.code) && item.Funds === ele.AMC)
                                            ).map(e => (
                                                <MenuItem value={e.id} key={e.id}>{e.name}</MenuItem>
                                            ))}

                                        </TextField>}
                                        {/* <TextField
                                        label="Amount"
                                        name="Amount"
                                        value={ele.Amount}
                                        onChange={(e) => HandleChangeTransactionCount(e, idx)}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        error={!!validationErrors.Amount}
                                        helperText={validationErrors.Amount}
                                        required
                                        sx={{ width: "50%" }}
                                    /> */}
                                        <NumericFormat
                                            label="Amount"
                                            name="view_amount"
                                            value={ele.view_amount}
                                            onChange={(e) => HandleChangeTransactionCount(e, idx)}
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            error={!!validationErrors.Amount}
                                            helperText={validationErrors.Amount}
                                            required
                                            sx={{ width: "50%" }}
                                            thousandsGroupStyle="lakh"
                                            thousandSeparator=","
                                            customInput={TextField}
                                            decimalScale={2}
                                        />
                                        <Clear onClick={() => HandlerRemoveScheme(ele)} sx={{ border: "1px solid #c4c4c4", height: "25px", width: "25px", borderRadius: "5px", color: "red" }} />
                                    </Box>
                                })}
                                <Box sx={{display:"flex",alignItems:"center",justifyContent:"center"}}>

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={isLoading}
                                        onClick={HandleAddOtherFunds}
                                        //sx={{ marginTop: 2, marginLeft: { xs: "25%", sm: "35%" }, width: { xs: "40%", sm: "30%" } }}
                                    >
                                        ADD OTHER FUNDS
                                    </Button>
                                </Box>
                            </>}
                            <TextField
                                label="Bank"
                                name="ac_no"
                                onChange={handleChange}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                error={!!validationErrors.ac_no}
                                helperText={validationErrors.ac_no}
                                required
                                select
                                defaultValue={formData.ac_no}
                            >
                                {userBank?.map((ele, indx) => {
                                    return <MenuItem key={indx} value={ele.ACNUM} >{`${ele.BANK_NAME} (${ele.ACNUM})`}</MenuItem>
                                })}
                            </TextField>
                            <TextField
                                label="Payment Mode"
                                name="payment_mode"
                                onChange={handleChange}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                error={!!validationErrors.payment_mode}
                                helperText={validationErrors.payment_mode}
                                required
                                select
                            >
                                {PaymentMode.filter((ele) => (storeState?.ACTIVEINVETOR?.type != 1 ? ele.code !== "UPI" : ele.code)).map((each, idx) => (
                                    <MenuItem key={idx} value={each.code}>
                                        {each.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={isLoading}
                                fullWidth
                                sx={{ marginTop: 2, width: "30%", marginLeft: "35%" }}
                            >
                                {isLoading ? <CircularProgress size={24} color="inherit" /> : 'INVEST'}
                            </Button>
                        </form>
                    </CardContent>
                    <Snackbar
                        open={isSuccess}
                        autoHideDuration={3000}
                        onClose={() => setIsSuccess(false)}
                        sx={{ marginBottom: 2 }}
                    >
                        <Alert severity='success' >{msg}</Alert>
                    </Snackbar>
                    <Snackbar
                        open={isFailure}
                        autoHideDuration={3000}
                        onClose={handleCloseSnackbar}
                        sx={{ marginBottom: 2 }}
                    ><Alert severity='error' >{msg}</Alert></Snackbar>
                </Card>
                {modelName == "TR" && <div>
                    <RTGSPayment state={state} setValidationErrors={setValidationErrors} validationErrors={validationErrors} isLoading={isLoading} setFormData={setFormData} formData={formData} open={open} setOpen={setOpen} setMsg={setMsg} setIsFailure={setIsFailure} setIsLoading={setIsLoading} setIsSuccess={setIsSuccess} accessToken={accessToken} />
                </div>}
                {modelName == "OL" && isSuccess && <div>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={{
                            position: 'absolute' as 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -75%)',
                            bgcolor: 'background.paper',
                            borderRadius: "10px",
                            minWidth: "40vw",
                            boxShadow: 24,
                            p: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                        }}>
                            <Typography variant='h5' m={2}>Online Payment Link</Typography>
                            <Divider sx={{ mb: 2, color: "blue" }} />
                            {isLoading ? <CircularProgress size={24} color="inherit" />
                                :
                                <Typography id="modal-modal-description">
                                    Kindly check your email for the payment link
                                </Typography>
                            }
                        </Box>
                    </Modal>
                </div>}
                {/* {modelName == "M" && <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -75%)',
                        bgcolor: 'background.paper',
                        borderRadius: "10px",
                        minWidth: "40vw",
                        boxShadow: 24,
                        p: 4,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }}>
                        <Typography variant='h5' >E Mandate</Typography>
                        <Divider />
                        <Typography id="modal-modal-description">
                            Transaction Completed
                        </Typography>
                        <Divider />
                        <TextField
                            label="Enter OTP"
                            name="otp"
                            onChange={(e) => { setOTP(e.target.value) }}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            error={!!validationErrors.otp}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={isLoading}
                            fullWidth
                            sx={{ marginTop: 2, width: "150px", height: "40px" }}
                            onClick={handleSubmitPOPUP}
                        >
                            {isLoading ? <CircularProgress size={24} color="inherit" /> : 'INVEST'}
                        </Button>
                    </Box>
                </Modal>
            </div>} */}
            </div>
        </>
    );
};

export default CreateMultipleOrdersNSE;