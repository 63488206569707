import React, { useEffect, useState } from 'react';
import { TextField, Button, Modal, Box, MenuItem, CircularProgress, Alert, Snackbar, Card, CardContent, Typography, Divider } from '@mui/material';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../Store/AppContext';
import RTGSPayment from './RTGSPayment';
import { NumericFormat } from 'react-number-format';
import { FormatNumber, parseNumber } from '../../../utils/formatNumber';
import { ProductCode, BankName, PaymentMode, isholiday, Name_Days } from '../../../Data/data';

const CreateOrderNSE = ({ accessToken }) => {
    const [storeState, dispatch] = useAppContext();
    const [open, setOpen] = React.useState(false);
    const [OTP, setOTP] = useState("");
    const [modelName, setModelName] = useState("");
    const handleClose = () => { setOpen(false); }
    const navigate = useNavigate();
    const { state }: any = useLocation();
    const { value } = state;
    const [isResume, setIsResume] = useState(false);
    const [validationErrors, setValidationErrors] = useState<any>({});
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isFailure, setIsFailure] = useState(false);
    const [userBank,setUserBank] = useState(storeState.ACTIVEINVETOR?.bank_list)

    const dateConverter = (str) => {
        const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        var date = new Date(str);
        var mnth = (date.getMonth());
        var day = ("0" + date.getDate()).slice(-2);
        var year = date.getFullYear();
        return `${day}-${month[mnth]}-${year}`;
    }

    const [msg, setMsg] = React.useState("");
    useEffect(() => {
        if (state) {
            if (Object.keys(state).length > 1) {
                setIsResume(true);
            } else {
                setIsResume(false);
            }
        }
    })

    const [formData, setFormData] = useState({
        "iin": storeState.ACTIVEINVETOR.folio.Folio,
        "sub_trxn_type": "N",
        "poa": "N",
        "poa_bank_trxn_type": "",
        "trxn_acceptance": "", //ALL for all
        "demat_user": "N",
        "dp_id": "",
        "bank": storeState?.ACTIVEINVETOR?.bank_list[0]?.BANK_CODE,
        "ac_no": storeState?.ACTIVEINVETOR?.bank_list[0]?.ACNUM,
        "ifsc_code":  storeState?.ACTIVEINVETOR?.bank_list[0]?.IFSC,
        "sub_broker_arn_code": "",
        "sub_broker_code": "",
        "euin_opted": "Y",
        "trxn_execution": "",
        "remarks": "",
        "payment_mode": "",
        "billdesk_bank": storeState?.ACTIVEINVETOR?.bank_list[0]?.BANK_CODE,
        "instrm_bank": "HDF",
        "instrm_ac_no": "",
        "instrm_no": "",
        "instrm_date": "",
        "instrm_branch": "",
        "instrm_charges": "",
        "micr": "",
        "rtgs_code": storeState?.ACTIVEINVETOR?.bank_list[0]?.IFSC,
        "neft_ifsc": "",
        "advisory_charge": "",
        "dd_charge": "",
        "cheque_deposit_mode": "",
        "debit_amount_type": "",
        "sip_micr_no": "",
        "sip_bank": "",
        "sip_branch": "",
        "sip_acc_no": "",
        "sip_ac_type": "",
        "sip_ifsc_code": "",
        "sip_paymech": "",
        "umrn": storeState?.ACTIVEINVETOR?.folio.umrn ? storeState?.ACTIVEINVETOR?.folio?.umrn : "",
        "ach_amt": "",
        "ach_fromdate": "",
        "ach_enddate": "",
        "until_cancelled": "",
        "Return_paymnt_flag": "N",
        "Client_callback_url": "",
        "Bank_holder_name": "", //have to fetch from Investment or folio after added its.
        "Bank_holder_name1": "",
        "Bank_holder_name2": "",
        "trxn_initiator": "",
        "trans_count": 1,
        "utr_no": "",
        "transfer_date": "",
        "investor_auth_log": "",
        "ach_exist": "",
        "process_mode": "",
        "channel_type": "",
        "folio": "",
        "ft_acc_no": "",
        "reinvest": "Z",
        "amount": "",
        "sip_from_date": "",
        "sip_end_date": "",
        "sip_freq": "",
        "sip_amount": "",
        "sip_period_day": "",
        "input_ref_no": "",
        "perpetual_flag": "",
        "insurance_enabled": "",
        "GOAL_BASED_SIP": "",
        "GOAL_TYPE": "",
        "GOAL_AMOUNT": "",
        "FREEDOM_SIP": "",
        "FREEDOM_TARGET_SCHEME": "",
        "FREEDOM_TENURE": "",
        "FREEDOM_SWP_AMOUNT": "",
        "FREEDOM_SCHEME_OPTION": "",
        "instrm_amount": "",  //sum of all the scheme amounts
        "fundType": "Various funds through NSE",
        "PayMode": "xxxxxxxxx",
        "TransactionData": [{
            "Funds": !isResume ? ProductCode.filter((ele) => ele?.AMC == value?.AMC && value?.Product_Code?.includes(ele.scheme_code))[0]?.AMC : "",
            "Scheme": !isResume ? ProductCode.filter((ele) => ele?.AMC == value?.AMC && value?.Product_Code?.includes(ele.scheme_code))[0]?.code : "",
            "Amount": "",
            "folio": ""
        }],
        "time_horizon": "",
        "view_amount": ""
    })

    const handleChange = (event) => {
        const { name, value } = event.target; 
        if(name == "ac_no"){
            const bank = userBank.filter((ele)=>ele.ACNUM == value)[0];
            setFormData((prev)=>({
                ...prev,
                "rtgs_code": bank?.IFSC,
                "billdesk_bank": bank?.BANK_CODE,
                "bank": bank?.BANK_CODE,
                "ac_no": bank?.ACNUM,
                "ifsc_code":  bank?.IFSC,
            }))
        }else if (name == "view_amount") {
            setFormData((prevData) => ({
                ...prevData,
                ["instrm_amount"]: parseNumber(value).toString(),
                [name]: value,
                TransactionData: [{
                    ...formData.TransactionData[0],
                    ['Amount']: parseNumber(value).toString(),
                }]
            }))
        }
        else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        setOpen(true)
        if (formData.payment_mode == "OL" || formData.payment_mode == "UPI" ) {
            setModelName(formData.payment_mode);
            const Data = { ...formData }
            axios.post(`${process.env.REACT_APP_BACKEND_HOST}v1/user/investment/create-order`, Data,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    const { data } = res;
                    if (!data.succ) {
                        setIsLoading(false);
                        setMsg(data.message)
                        setIsFailure(true);
                        return;
                    }
                    setIsLoading(false);
                    setIsSuccess(true);
                    setMsg(`Order submitted successfully for Rs ${formData.view_amount}`)
                    setTimeout(() => {
                        navigate(`/dashboardAdmin/investment/nse/details/${storeState.ACTIVEINVETOR.folio.Folio}`)
                    }, 3000);
                }).catch(({ response }) => {
                    setIsLoading(false);
                    const { data } = response;
                    //setValidationErrors(data.validationErrors);
                })
        } else if (formData.payment_mode == "M") {
            setModelName(formData.payment_mode);
            // if (BankNotOnlinepayment.includes(formData.billdesk_bank) && formData.payment_mode == "M") {
            //     setMsg(`Online Payment Method not available for ${BankName[formData.billdesk_bank]} bank `)
            //     setIsFailure(true);
            //     return;
            // }
            if (formData.umrn == "") {
                axios.post(`${process.env.REACT_APP_BACKEND_HOST}v1/user/investment/nse/umrn`, {
                    iin: storeState.ACTIVEINVETOR.folio.Folio,
                    folio_id: storeState.ACTIVEINVETOR.folio._id
                }, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }).then((response) => {
                    const { data } = response;
                    console.log(data)
                    if (!data.succ) {
                        setIsLoading(false);
                        setMsg(data.message)
                        setIsFailure(true);
                        return;
                    }
                    const Data = { ...formData, "umrn": data.umrn }
                    axios.post(`${process.env.REACT_APP_BACKEND_HOST}v1/user/investment/create-order`, Data,
                        {
                            headers: { Authorization: `Bearer ${accessToken}` }
                        }).then(res => {
                            const resData = res.data;
                            if (!resData.succ) {
                                setIsLoading(false);
                                setMsg(resData.message)
                                setIsFailure(true);
                                return;
                            }
                            setIsLoading(false);
                            setIsSuccess(true);
                            setMsg(`Order submitted successfully for Rs ${formData.instrm_amount}`)
                            setTimeout(() => {
                                navigate(`/dashboardAdmin/investment/nse/details/${storeState.ACTIVEINVETOR.folio.Folio}`)
                            }, 3000);
                        }).catch(({ response }) => {
                            setIsLoading(false);
                            setIsFailure(true)
                            const { data } = response;
                            setMsg(data?.message)
                            //setValidationErrors(data.validationErrors);
                        })
                }).catch((error) => {
                    setIsLoading(false);
                    setIsFailure(true);
                    const { data } = error;
                    setMsg(data.message)
                })
            } else {

                const Data = { ...formData }
                axios.post(`${process.env.REACT_APP_BACKEND_HOST}v1/user/investment/create-order`, Data,
                    {
                        headers: { Authorization: `Bearer ${accessToken}` }
                    }).then(res => {
                        const resData = res.data;
                        if (!resData.succ) {
                            setIsLoading(false);
                            setMsg(resData.message)
                            setIsFailure(true);
                            return;
                        }
                        setIsLoading(false);
                        setIsSuccess(true);
                        setMsg(`Order submitted successfully for Rs ${formData.instrm_amount}`)
                        setTimeout(() => {
                            navigate(`/dashboardAdmin/investment/nse/details/${storeState.ACTIVEINVETOR.folio.Folio}`)
                        }, 3000);
                    }).catch(({ response }) => {
                        setIsLoading(false);
                        setIsFailure(true)
                        const { data } = response;
                        setMsg(data?.message)
                        //setValidationErrors(data.validationErrors);
                    })
            }

        }
        // else if (formData.payment_mode == "M") {
        //     //payment for e mandate
        //     axios.post(`${process.env.REACT_APP_BACKEND_HOST}v1/user/investment/creatotbmotp`, formData,
        //         {
        //             headers: { Authorization: `Bearer ${accessToken}` }
        //         }).then(res => {
        //             const { data } = res;
        //             console.log(res)
        //             if (!data.succ) {
        //                 setIsLoading(false);
        //                 setMsg(data.message)
        //                 setIsFailure(true);
        //                 return;
        //             }
        //             setModelName("M")
        //             setIsSuccess(true);
        //             setMsg(`OTP sent to ${storeState.ACTIVEINVETOR.user_id.email}`)
        //             setIsLoading(false);
        //             return;
        //         }).catch(({ response }) => {
        //             setIsLoading(false);
        //             console.log(response)
        //             //const { data } = response;
        //             //setValidationErrors(data.validationErrors);
        //             return;
        //         })
        // } 
        else if (formData.payment_mode == "TR") {
            setModelName("TR")
            setIsLoading(false)
        }
    };

    const handleSubmitPOPUP = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        await axios.post(`${process.env.REACT_APP_BACKEND_HOST}v1/user/investment/verify_otp_createotbm`, { otp: OTP },
            {
                headers: { Authorization: `Bearer ${accessToken}` }
            }).then((res) => {

                if (!res.data.succ) {
                    setMsg(res.data.message)
                    setIsFailure(true);
                    setIsLoading(false);
                    return;
                }
                axios.post(`${process.env.REACT_APP_BACKEND_HOST}v1/user/investment/create-order`, formData,
                    {
                        headers: { Authorization: `Bearer ${accessToken}` }
                    }).then(res => {
                        const { data } = res;
                        if (!data.succ) {
                            setIsLoading(false);
                            setMsg(data.message)
                            setIsFailure(true);
                            return;
                        }
                        setIsLoading(false);
                        setIsSuccess(true);
                        setMsg(`Order submitted successfully for Rs ${FormatNumber(formData.instrm_amount)}`)
                        setTimeout(() => {
                            formData.fundType == "Various funds through NSE" ?
                                navigate(`/dashboardAdmin/investment/nse/details/${state.state.folio.Folio}`) :
                                navigate(`/dashboardAdmin/investment/details/${state.state.folio.Folio}`)
                        }, 3000)

                    }).catch(({ response }) => {
                        setIsLoading(false);
                        const { data } = response;
                        setIsFailure(true);
                        setMsg(data.message);
                        setValidationErrors(data.validationErrors);
                        return;
                    })
                return;
            }
            ).catch(({ response }) => {
                setIsLoading(false);
                console.log("response", response)
                const { data } = response;
                setIsFailure(true);
                setMsg(data.message);
                data.validationErrors && setValidationErrors(data.validationErrors);
                return;
            })
    }

    const handleCloseSnackbar = () => {
        setIsFailure(false);
    };
    return (<>
         {["Sunday","Saturday"].includes(Name_Days)?<div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", marginTop: "2rem" }}><Typography sx={{ background: "yellow", padding: "4px", borderRadius: "10px", textAlign: "center",width:{xs:"90%",sm:"600px"} }} >{`Kindly note that today is a holiday as exchanges are closed on ${Name_Days}. Also, ${dateConverter(isholiday?.date)} is a holiday on account of ${isholiday?.Desc}.`}</Typography></div>:<div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", marginTop: "2rem" }}><Typography sx={{ background: "yellow", padding: "4px", borderRadius: "10px", textAlign: "center",width:{xs:"90%",sm:"600px"} }} >{`Kindly note that ${dateConverter(isholiday?.date)} is a holiday on account of ${isholiday?.Desc}.`}</Typography></div>}
        <div style={{ margin: "2rem" }}>
            <Card sx={{ maxWidth: 600, margin: '0 auto' }}>
                <CardContent>
                    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                        {/* {!isResume && <TextField
                            label="Fund"
                            name="amc"
                            onChange={handleChange}
                            defaultValue={formData.amc}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            select
                            disabled={isResume}
                            required
                            error={!!validationErrors.Fund}
                            helperText={validationErrors.Fund}
                        >
                            {
                                Funds.map((ele, idx) => {
                                    return <MenuItem key={idx} value={ele.code} >{ele.name}</MenuItem>
                                })
                            }
                        </TextField>} */}
                        {/* <TextField
                            label="IIN"
                            name="iin"
                            value={formData.iin}
                            onChange={handleChange}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            disabled
                            error={!!validationErrors.iin}
                            helperText={validationErrors.iin}
                            required /> */}
                        {/* <TextField
                            label="Amount"
                            name="Amount"
                            value={formData.TransactionData[0].Amount}
                            onChange={handleChange}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            error={!!validationErrors.amount}
                            helperText={validationErrors.amount}
                            required
                        /> */}
                        <NumericFormat
                            type="text"
                            label="Amount"
                            name="view_amount"
                            value={formData.view_amount}
                            onChange={handleChange}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            error={!!validationErrors.view_amount}
                            helperText={validationErrors.view_amount}
                            required thousandsGroupStyle="lakh" thousandSeparator=","
                            customInput={TextField}
                            decimalScale={2}
                        />
                        <TextField
                            label="Scheme"
                            name="product_code"
                            onChange={handleChange}
                            value={formData.TransactionData[0].Scheme}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            error={!!validationErrors.product_code}
                            helperText={validationErrors.product_code}
                            required
                            select
                            disabled
                        >
                            {ProductCode.map((each, idx) => (
                                <MenuItem key={idx} value={each.code}>
                                    {each.name}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            label="Bank"
                            name="ac_no"
                            onChange={handleChange}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            error={!!validationErrors.ac_no}
                            helperText={validationErrors.ac_no}
                            required
                            select
                            defaultValue={formData.ac_no}
                        >
                            {userBank.map((ele, indx) => {
                                    return <MenuItem key={indx} value={ele.ACNUM} >{`${ele.BANK_NAME} (${ele.ACNUM})`}</MenuItem>
                                })}
                        </TextField>
                        {/* <TextField
                            label="Account Number"
                            name="ac_no"
                            value={formData.ac_no}
                            onChange={handleChange}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            error={!!validationErrors.ac_no}
                            helperText={validationErrors.ac_no}
                            required

                        /> */}
                        {/* <TextField
                            label="Bank IFSC Code"
                            name="ifsc_code"
                            value={formData.ifsc_code}
                            onChange={handleChange}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            error={!!validationErrors.ifsc_code}
                            helperText={validationErrors.ifsc_code}
                            required
                        /> */}
                        <TextField
                            label="Payment Mode"
                            name="payment_mode"
                            onChange={handleChange}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            error={!!validationErrors.payment_mode}
                            helperText={validationErrors.payment_mode}
                            required
                            select
                        >
                            {PaymentMode.map((each, idx) => (
                                <MenuItem key={idx} value={each.code}>
                                    {each.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        {/* {formData.payment_mode == "OL" && <>
                            <TextField
                                label="Bill Desk Bank"
                                name="billdesk_bank"
                                onChange={handleChange}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                error={!!validationErrors.euin}
                                helperText={validationErrors.euin}
                                select
                            >
                                {BankName.slice(0, 1).map((ele, indx) => {
                                    return <MenuItem key={indx} value={ele.code} >{ele.title}</MenuItem>
                                })}
                            </TextField>
                            <TextField
                                label="Instrument Amount"
                                name="instrm_amount"
                                onChange={handleChange}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                error={!!validationErrors.instrm_amount}
                                helperText={"Equal to Sum of Transaction amount"}
                            />
                        </>
                        } */}
                        {/* {
                            formData.payment_mode == "M" && <>
                                <TextField
                                    label="Instrument Bank"
                                    name="instrm_bank"
                                    onChange={handleChange}
                                    defaultValue={formData.instrm_bank}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    error={!!validationErrors.instrm_amount}
                                    select
                                >
                                    {BankName.filter((ele) => ele.code == "HDF").map((ele, indx) => {
                                        return <MenuItem key={indx} value={ele.code} >{ele.title}</MenuItem>
                                    })}
                                </TextField>
                                <TextField
                                    label="Instrument Amount"
                                    name="instrm_amount"
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    error={!!validationErrors.instrm_amount}
                                    helperText={"Equal to Sum of Transaction amount"}
                                />
                            </>
                        } */}
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={isLoading}
                            fullWidth
                            sx={{ marginTop: 2 }}
                        >
                            {isLoading ? <CircularProgress size={24} color="inherit" /> : 'INVEST'}
                        </Button>
                    </form>
                </CardContent>
                <Snackbar
                    open={isSuccess}
                    autoHideDuration={3000}
                    onClose={() => setIsSuccess(false)}
                    sx={{ marginBottom: 2 }}
                >
                    <Alert severity='success' >{msg}</Alert>
                </Snackbar>
                <Snackbar
                    open={isFailure}
                    autoHideDuration={3000}
                    onClose={handleCloseSnackbar}
                    sx={{ marginBottom: 2 }}
                ><Alert severity='error' >{msg}</Alert></Snackbar>
            </Card>
            {modelName == "TR" && <div>
                <RTGSPayment state={state} setValidationErrors={setValidationErrors} validationErrors={validationErrors} isLoading={isLoading} setFormData={setFormData} formData={formData} open={open} setOpen={setOpen} setMsg={setMsg} setIsFailure={setIsFailure} setIsLoading={setIsLoading} setIsSuccess={setIsSuccess} accessToken={accessToken} />
            </div>}
            {modelName == "OL" && isSuccess && <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -75%)',
                        bgcolor: 'background.paper',
                        borderRadius: "10px",
                        minWidth: "40vw",
                        boxShadow: 24,
                        p: 4,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }}>
                        <Typography variant='h5' m={2}>Online Payment Link</Typography>
                        <Divider sx={{ mb: 2, color: "blue" }} />
                        {isLoading ? <CircularProgress size={24} color="inherit" />
                            :
                            <Typography id="modal-modal-description">
                                Kindly check your email for the payment link
                            </Typography>
                        }
                    </Box>
                </Modal>
            </div>}
            {/* {modelName == "M" && <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -75%)',
                        bgcolor: 'background.paper',
                        borderRadius: "10px",
                        minWidth: "40vw",
                        boxShadow: 24,
                        p: 4,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }}>
                        <Typography variant='h5' >E Mandate</Typography>
                        <Divider />
                        <Typography id="modal-modal-description">
                            Transaction Completed
                        </Typography>
                        <Divider />
                        <TextField
                            label="Enter OTP"
                            name="otp"
                            onChange={(e) => { setOTP(e.target.value) }}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            error={!!validationErrors.otp}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={isLoading}
                            fullWidth
                            sx={{ marginTop: 2, width: "150px", height: "40px" }}
                            onClick={handleSubmitPOPUP}
                        >
                            {isLoading ? <CircularProgress size={24} color="inherit" /> : 'INVEST'}
                        </Button>
                    </Box>
                </Modal>
            </div>} */}
        </div>
    </>
    );
};

export default CreateOrderNSE;